<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <b-row class="mb-3">
              <b-col md="4">
                <b-form-input v-model="filter" type="search" :placeholder="cvSearchText"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" v-if="ratingObjList && ratingObjList.length > 0">
                <b-table hover responsive :items="ratingObjList" :fields="columns" :no-border-collapse="true"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                  :current-page="currentPage" :per-page="perPage">
                  <template v-slot:cell(created_on)="data">
                    {{ getFormattedDateTime(data.item.created_on) }}
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1"></b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { Ratings } from "../../../FackApi/api/rating.js"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "RatingList",
  components: {
  },
  data () {
    return {
      cvCardTitle: "Ratings",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Rating",
      cvAddModalHeader: "Add Rating",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Rating List Response",
      showModelRatingAdd: false,
      showModelRatingEdit: false,
      showModelRatingDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "module id", key: "module_id", class: "text-left align-text-top", sortable: true },
        { label: "module name", key: "module_name", class: "text-left align-text-top", sortable: true },
        { label: "rating", key: "rating", class: "text-left align-text-top", sortable: true },
        { label: "rating comment", key: "rating_comment", class: "text-left align-text-top", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left align-text-top", sortable: true }
      ],
      ratingObjList: null,
      ratingEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null
    }
  },
  mounted () {
    this.ratingList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * ratingList
     */
    async ratingList () {
      try {
        let ratingListResp = await Ratings.ratingList(this)
        if (ratingListResp.resp_status) {
          this.ratingObjList = ratingListResp.resp_data.data
          this.totalRows = ratingListResp.resp_data.count
        }
        else {
          this.toastMsg = ratingListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at ratingList() and Exception:", err.message)
      }
    },
    /**
     * ratingAdd
     */
    ratingAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/rating_add")
        }
        else {
          this.showModelRatingAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at ratingAdd() and Exception:", err.message)
      }
    },
    /**
     * ratingEdit
     */
    ratingEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/rating_edit/" + this.ratingEditObj.rating_id)
        }
        else {
          this.ratingEditObj = item
          this.showModelRatingEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at ratingEdit() and Exception:", err.message)
      }
    },
    /**
     * showRatingDeleteDialog
     */
    showRatingDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelRatingDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showRatingDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * ratingDelete
     */
    async ratingDelete () {
      try {
        let ratingDelResp = await Ratings.ratingDelete(this, this.delObj.rating_id)
        await ApiResponse.responseMessageDisplay(this, ratingDelResp)

        if (ratingDelResp && !ratingDelResp) {
          this.showModelRatingDelete = false
          return false
        }

        let index = this.ratingObjList.indexOf(this.delObj)
        this.ratingObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelRatingDelete = false
      }
      catch (err) {
        console.error("Exception occurred at ratingDelete() and Exception:", err.message)
      }
    },
    /**
     * closeRatingAddModal
     */
    closeRatingAddModal (ratingAddData) {
      try {
        if (ratingAddData) {
          if (this.ratingObjList && this.ratingObjList.length >= 1) {
            let ratingObjLength = this.ratingObjList.length
            let lastId = this.ratingObjList[ratingObjLength - 1]["id"]
            ratingAddData.id = lastId + 1
          }
          else {
            this.ratingObjList = []
            ratingAddData.id = 11111
          }

          ratingAddData.created_on = moment()
          this.ratingObjList.unshift(ratingAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelRatingAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeratingAddModal() and Exception:", err.message)
      }
    },
    /**
     * closeRatingEditModal
     */
    closeRatingEditModal () {
      try {
        this.showModelRatingEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeRatingEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
