/*eslint-disable */
// import RatingModel from '../../Model/Rating'
import request from '../../Utils/curl'

let Ratings = {
  /**
   * ratingList
   */
  async ratingList (context) {
    try{
      let post_data = new FormData();
  
      return await request.curl(context, "rating_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at ratingList() and Exception:',err.message)
    }
  },

  /**
   * ratingView
   */
  async ratingView (context, ratingId) {
    try {      
      let post_data = new FormData();
      post_data.append('rating_id', ratingId);
      return await request.curl(context, "rating_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at ratingView() and Exception:",err.message)
    }    
  },

  /**
   * ratingAdd
   */
  async ratingAdd (context, ratingObj) {
    try{
      let post_data = new FormData();
    
      for (let key in ratingObj) {
        post_data.append(key, ratingObj[key]);
      }

      return await request.curl(context, "rating_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at ratingAdd() and Exception:',err.message)
    }
  },

  /**
   * ratingEdit
   */
  async ratingEdit (context, ratingObj) {
    try{
      let post_data = new FormData();
    
      for (let key in ratingObj) {
        post_data.append(key, ratingObj[key]);
      }

      return await request.curl(context, "rating_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at ratingEdit() and Exception:',err.message)
    }
  },

  /**
   * ratingDelete
   */
  async ratingDelete (context, ratingId) {
    try{
      let post_data = new FormData();
      
      post_data.append('rating_id', ratingId);

      return await request.curl(context, "rating_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at ratingDelete() and Exception:',err.message)
    }
  }
}

export {
  Ratings
}
